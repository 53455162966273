
import './styles/quasar.scss'
import '@quasar/extras/material-icons/material-icons.css'
import Loading from 'quasar/src/plugins/Loading.js';
import langDe from 'quasar/lang/de'
// To be used on app.use(Quasar, { ... })
export default {
  config: {
    loading: {}
  },
  plugins: {
    Loading
  },
  lang: langDe
}