<template>
  <loading :loading="false" />
</template>

<script>
import Loading from '../components/Loading.vue';
export default {
  components: { Loading },};
</script>

<style scoped>
</style>