<template>
  <div class="column items-center space justify-around" style="height: 250px">
    <q-space></q-space>
    <q-input v-model="name" label="Benutzername"
      ><template v-slot:append>
        <q-icon name="person" />
      </template>
    </q-input>
    <q-input
      v-model="password"
      :type="isPwd ? 'password' : 'text'"
      label="Passwort"
      v-on:keyup.enter="
        name != '' && password != ''
          ? handleLogin()
          : (message = 'Bitte Felder ausfüllen!')
      "
    >
      <template v-slot:append>
        <q-icon
          :name="isPwd ? 'visibility_off' : 'visibility'"
          class="cursor-pointer"
          @click="isPwd = !isPwd"
        />
      </template>
    </q-input>
    <q-space></q-space>
    <q-btn
      @click="
        name != '' && password != ''
          ? handleLogin()
          : (message = 'Bitte Felder ausfüllen!')
      "
      >Einloggen</q-btn
    >

    <p>{{ message }}</p>
    <loading :loading="loading" />
  </div>
</template>

<script>
import { ref } from "vue";
import Loading from "../components/Loading.vue";
export default {
  components: { Loading },
  name: "Login-Page",
  data() {
    return {
      loading: false,
      name: ref(""),
      password: ref(""),
      isPwd: ref(true),
      message: ref(""),
    };
  },

  methods: {
    switchSite() {
      this.$router.push("/");
    },
    handleLogin() {
      this.loading = true;
      setTimeout(() => {
        this.$store
          .dispatch("auth/login", {
            username: this.name,
            password: this.password,
          })
          .then(
            async () => {
              this.$router.push("/dashboard");
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              console.log(error);
              this.message = "Benutzername oder Passwort falsch!";
              /*this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();*/
            }
          );
      }, 2000);
    },
  },
};
</script>
