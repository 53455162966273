<template>
  <div>
  </div>
</template>

<script>
import { useQuasar } from "quasar";
import { onBeforeUnmount } from "vue";

export default {
  setup() {
    const $q = useQuasar();
    let timer;

    onBeforeUnmount(() => {
      if (timer !== void 0) {
        clearTimeout(timer);
        $q.loading.hide();
      }
    });

    return {
      showLoading() {
        $q.loading.show();
      },
       hideLoading() {
        $q.loading.hide();
      },
    };
  },
  mounted(){
    if(this.$props.loading){
      this.showLoading()
    }else{
      this.hideLoading();
    }
  },
  watch:{
    loading(newVal){
      if(newVal){
        this.showLoading()
      }else{
        this.hideLoading();
      }
    }
  },
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style>
</style>