<template>
  <q-layout>
    <q-drawer
      v-if="currentUser"
      v-model="drawer"
      :width="200"
      :breakpoint="400"
    >
      <q-scroll-area
        style="
          height: calc(100% - 150px);
          margin-top: 150px;
          border-right: 1px solid #ddd;
        "
      >
        <q-list padding>
          <q-item clickable v-ripple @click="this.$router.push('/dashboard')">
            <q-item-section avatar>
              <q-icon name="dashboard" />
            </q-item-section>

            <q-item-section> Dashboard </q-item-section>
          </q-item>
          <q-separator />
          <q-item
            clickable
            v-ripple
            @click="this.$router.push('/dashboard/Buried')"
          >
            <q-item-section avatar>
              <q-icon name="img:Quickstarticons/Bestattete Liste.png" />
            </q-item-section>

            <q-item-section> Bestattete </q-item-section>
          </q-item>

          <q-item
            clickable
            v-ripple
            @click="this.$router.push('/dashboard/Gravekeeper')"
          >
            <q-item-section avatar>
              <q-icon name="img:Quickstarticons/Grabpfleger Liste.png" />
            </q-item-section>

            <q-item-section> Grabpfleger </q-item-section>
          </q-item>

          <q-item
            clickable
            v-ripple
            @click="this.$router.push('/dashboard/Berth')"
          >
            <q-item-section avatar>
              <q-icon name="img:Quickstarticons/Liegestätte Liste.png" />
            </q-item-section>

            <q-item-section> Grabstätte </q-item-section>
          </q-item>
          <q-separator />
          <q-item clickable v-ripple @click="this.$router.push('/settings')">
            <q-item-section avatar>
              <q-icon name="settings" />
            </q-item-section>

            <q-item-section> Einstellungen </q-item-section>
          </q-item>
          <a
            style="text-decoration: none; color: black"
            href="https://firebasestorage.googleapis.com/v0/b/grabverwaltung.appspot.com/o/Anleitung.pdf?alt=media&token=34bdaf5c-00a7-4a19-b73e-4e6e4253193d"
          >
            <q-item clickable v-ripple>
              <q-item-section avatar>
                <q-icon name="description" />
              </q-item-section>

              <q-item-section> Anleitung </q-item-section>
            </q-item>
          </a>
          <q-item
            v-if="currentUser.graveyard == 2"
            clickable
            v-ripple
            @click="this.$router.push('/siteplan')"
          >
            <q-item-section avatar>
              <q-icon name="map" />
            </q-item-section>

            <q-item-section> Lageplan </q-item-section>
          </q-item>
          <a
            v-if="currentUser.graveyard == 3"
            style="text-decoration: none; color: black"
            href="https://firebasestorage.googleapis.com/v0/b/grabverwaltung.appspot.com/o/Satzung_GVReiste.pdf?alt=media&token=a20995f3-4564-4fdb-88f0-3e16cfae9510"
          >
            <q-item clickable v-ripple>
              <q-item-section avatar>
                <q-icon name="account_balance" />
              </q-item-section>

              <q-item-section> Satzung </q-item-section>
            </q-item>
          </a>
          <a
            v-if="currentUser.graveyard == 3"
            style="text-decoration: none; color: black"
            href="https://firebasestorage.googleapis.com/v0/b/grabverwaltung.appspot.com/o/Geb%C3%BChren_GVReiste.pdf?alt=media&token=329d0f3b-0062-42c5-ad3d-8ef6e19636f9"
          >
            <q-item clickable v-ripple>
              <q-item-section avatar>
                <q-icon name="payments" />
              </q-item-section>

              <q-item-section> Gebühren </q-item-section>
            </q-item>
          </a>
        </q-list>
      </q-scroll-area>
      <q-img
        class="absolute-top"
        src="./assets/dark-grey.jpeg"
        style="height: 150px"
      >
        <div class="absolute-bottom bg-transparent">
          <q-icon name="account_circle" size="32px"></q-icon>
          <div class="text-weight-bold">@{{ currentUser.username }}</div>
          <div class="">
            {{ currentUser.firstname }} {{ currentUser.lastname }}
          </div>
          <div>Grabverwaltung {{ currentUser.graveyardName }}</div>
        </div>
      </q-img>
    </q-drawer>
    <q-header :class="appbardesign" elevated v-if="currentUser">
      <q-toolbar class="justify-between">
        <q-btn flat @click="drawer = !drawer" round dense icon="menu" />
        <q-toolbar-title
          shrink
          class="cursor"
          @click="this.$router.push('/dashboard')"
          >Grabverwaltung {{ currentUser.graveyardName }}</q-toolbar-title
        >
        <q-space></q-space>
        <q-space></q-space>
        <div class="sidebox">
          <q-btn flat round dense icon="logout" @click="logout"> Logout </q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <q-header :class="appbardesign" elevated v-else>
      <q-toolbar>
        <q-toolbar-title class="cursor" @click="this.$router.push('/')"
          >Grabverwaltung</q-toolbar-title
        >
        <q-space></q-space>
        <q-btn
          v-if="this.$route.path != '/login'"
          flat
          round
          dense
          icon="login"
          @click="this.$router.push('/login')"
        >
          Login
        </q-btn>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import axios from "axios";
import authHeader from "./services/Authentication/auth-header";
import { ref } from "vue";
export default {
  name: "app",
  data() {
    return {
      appbardesign: "shadow-5",
    };
  },
  setup() {
    return {
      drawer: ref(true),
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    description() {
      window.location = "";
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  created() {
    if (this.currentUser) {
      axios
        .post(
          "/api/check/" + "checktoken",
          {},
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          if (Math.round(new Date() / 1000 + 7200) > response.data.exp) {
            this.logout();
          }
          return response.data;
        })
        .catch((err) => {
          console.log(err);
          this.logout();
        });
    }
  },
};
</script>

<style>
.cursor {
  cursor: pointer;
}

.rLink {
  color: white;
  text-decoration: none;
  padding: 8px;
  border-radius: 10px;
  margin: 4px;
}

.rLink:hover {
  background: rgba(134, 134, 134, 0.3);
}

.div-box {
  margin: 16px;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.heading {
  font-size: 20px;
  color: #004011;
  font-style: italic;
  align-self: center;
}

.sidebox {
  width: 150px;
  text-align: right;
}

.show {
  display: block;
}
</style>

<style lang="sass">
.my-card
  width: 100%
  max-width: 200px
  margin: 10px
</style>
