import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {

    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
  {

    path: '/createAll',
    name: 'CreateAll',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/create/CreateAll.vue')
  },

  {
    path: '/dashboard/:name',
    name: 'Specific-Page',
    component: () => import('../components/Specific-Page.vue')
  },
  {
    path: '/buried/:id',
    name: 'SingleBuried',
    component: () => import('../components/single/Buried.vue')
  },
  {
    path: '/gravekeeper/:id',
    name: 'SingleGravekeeper',
    component: () => import('../components/single/Gravekeeper.vue')
  },
  {
    path: '/berth/:id',
    name: 'SingleBerth',
    component: () => import('../components/single/Berth.vue')
  },
  {
    path: '/siteplan',
    name: 'Siteplan',
    component: () => import('../views/Siteplan.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings/Settings.vue'),
    children: [
      {
        path: '',
        name: "Profile",
        component: () => import('../views/Settings/Profile.vue'),
      },
      {
        path: 'changepassword',
        name: "ChangePassword",
        component: () => import('../views/Settings/ChangePassword.vue'),
      },
      
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else if (to.fullPath == "/") {
    next('/dashboard');
  } else {
    next()
  }
});

export default router
